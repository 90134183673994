import React from "react";
import { Link } from "gatsby";

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';

// Page routes
import * as ROUTES from '../constants/routes';

const Disclaimer = () => {
  return (
    <Layout>
        <SEO 
            title="Page Not Found | Mike Martin Law"
        />
        <Card>
            <h1 className="text-4xl font-bold mb-8">The page you're looking for could not be found</h1>
            <Link 
              to={ROUTES.INDEX}
              className="text-secondary underline"
            >
              Go to the home page
            </Link>
        </Card>
    </Layout>
  )
};

export default Disclaimer;